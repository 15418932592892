
import React, { ReactNode, useMemo, useState } from 'react';
import questions from '../data/data-elsa--sat.json';
import { Copy } from './Copy';
import TeX from '@matejmazur/react-katex';
import { blockStatement } from '@babel/types';

const categories: string[] = Array.from(new Set(questions.map(q => q.category)))

export function ElsaSatExtractor() {

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [categoryFilter, setCategoryFilter] = useState<string>("All");
    const filteredQuestions = useMemo(() => questions.filter(q => categoryFilter === "All" || q.category === categoryFilter), [categoryFilter]);
    const question = filteredQuestions[currentQuestionIndex];
    const tags = `elsa-sat, ${question.id}`;
    const solution = CleanMath(question.solution);

    const changeCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newCategory = event.target.value;
        
        if (newCategory !== categoryFilter) {
            setCurrentQuestionIndex(0);
            setCategoryFilter(newCategory);
        }
    }

    return (
        <>
            <Section title="Controls">
                <button disabled={currentQuestionIndex === 0} onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}>Prev</button>
                <button disabled={currentQuestionIndex + 1 >= filteredQuestions.length} onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}>Next</button>
                <br />
                <br />
                Category: 
                <select onChange={changeCategory}>
                    <option value="All">-- All --</option>
                    {categories.map(c => (
                        <option value={c}>{c}</option>
                    ))}

                </select>
                <br/>
                <br />
                {currentQuestionIndex + 1} / {filteredQuestions.length}
            </Section>

            <Section title="Meta">
                <div>
                    <p style={{background: question.parentQuestionId ? "red" : undefined}}>Has parent question:  {(question.parentQuestionId !== undefined).toString()}</p>
                    <p style={{background: question.shouldMixAnswers ? undefined : "red"}}>Shuffle answers: {question.shouldMixAnswers.toString()}</p>
                    <p>Tags: {tags} <Copy value={tags} /></p>
                    <p>Type: Single-choice</p>
                    <p>Category: {question.category}</p>
                    {categoryFilter &&
                        <p>Category size: {filteredQuestions.length}</p>
                    }
                    <p>Has image: {(question.imagePath !== undefined).toString()}</p>
                </div>
            </Section>

            <Section title="Question">
                {question.imagePath &&
                    <p style={{textAlign: "center"}}>
                        <img src={`/img/elsa-sat/${question.imagePath}`} />
                    </p>
                }
                <Math val={CleanMath(question.question)} />
            </Section>

            {question.answers &&
                <Section title="Answers">
                    {question.answers?.map((a, i) => (
                        <Answer text={CleanMath(a.text)} isCorrect={a.isCorrect} index={i} />
                    ))}
                </Section>
            }

            {question.openQuestionAnswers &&
                <Section title="Accepted answers">
                    <ul>
                        {question.openQuestionAnswers?.map(q => (
                            <li>{q}</li>
                        ))}
                    </ul>
                </Section>
            }

            <Solution value={solution}/>
        </>
    )
}

function Solution(props: {value: string}) {
    const [isRendering, setIsRendering] = useState(true);
    const toggleRendering = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRendering(!isRendering)
    }

    const title = (
        <>
            Solution
            
            &nbsp;
            
            <label htmlFor="render-solution">
                <input type="checkbox" id="render-solution" checked={isRendering} onChange={toggleRendering}/>
                render
            </label>

            &nbsp;

            <Copy value={props.value} />
        </>
    )

    return (
        <Section title={title}>
            {isRendering
                ? <Math val={props.value} />
                : props.value
            }
        </Section>
    )
}
 
const Section : React.FC<{title: ReactNode}> = props => {
    return (
        <fieldset style={{marginTop: "30px", padding: "16px"}}>
            <legend>{props.title}</legend>
            {props.children}
        </fieldset>
    );
}

const Answer : React.FC<{text: string, isCorrect: Boolean, index: number}> = props => {
    const border = `1px solid ${props.isCorrect ? 'green' : 'red'}`;

    return (
        <fieldset style={{border: border}}>
            <legend>{String.fromCharCode(65 + props.index)} <Copy value={props.text}/></legend>
            <Math val={props.text} />
        </fieldset>
    )
}

const Math : React.FC<{val: string}> = ({val}) => {
    const blocks = SplitMath(val);

    return (
        <>
            { blocks.map(b => b.isMath
                    ? <TeX math={b.text} />
                    : b.text
              )
            }
        </>
    )
}

type Block = {
    text: string;
    isMath: boolean;
}

function CleanMath(math: string): string {
    return math.replaceAll('$$', '$$').replaceAll('££', '$$');
}

function SplitMath(math: string): Block[] {
    var isMathMode = false;

    return math.split('$').reduce((blocks, b) => {
        if (b === "") {
            isMathMode = true;
            return blocks;
        } else if (isMathMode) {
            isMathMode = false;
            return blocks.concat({text: b, isMath: true});
        } else {
            isMathMode = true;
            return blocks.concat({text: b, isMath: false});
        }
    }, [] as Block[]);
}