import { Fragment, useState } from "react";
import { formatWithOptions } from "util";
import questionsSet from "../data/data.json";

type QuestionsSet = {
    Definitions: Definition[];
    ItemIds: ItemId[];
}

type ItemId = {
    id: string;
    isSupported: Boolean;
    section: number | null;
}

type Definition = {
    ItemId: string;
    ItemType: string;
    AssignmentHtml: string;
    Options: Option[];
    SolutionSteps: string[];

}

type Option = {
    Key: string;
    IsCorrect: boolean;
    OptionHtml: string;
}

export function Extractor() {
    const supportedQuestions: Definition[] = questionsSet.Definitions.filter(question => {
        return questionsSet.ItemIds.some(itemId => itemId.id === question.ItemId && itemId.isSupported);
    })
    const [currentIndex, setCurrentIndex] = useState(0);
    const question = supportedQuestions[currentIndex];
    const itemId = questionsSet.ItemIds.find(i => i.id === question.ItemId)

    const goPrev = () => { setCurrentIndex(currentIndex - 1) }
    const goNext = () => { setCurrentIndex(currentIndex + 1) }

    return (
        <div>
            <Controls
                onPrev={currentIndex > 0 ? goPrev : undefined}
                onNext={currentIndex < supportedQuestions.length - 1 ? goNext : undefined}
                itemId={itemId}
            />
            <Question question={question} number={currentIndex+1}/>
            <Answers options={question.Options}/>
            <Solution solution={question.SolutionSteps}/>
        </div>
    )
}

function OptionView(props: {option: Option}) {
    return (<div>
        
        <DangerousHtmlBlock value={props.option.OptionHtml} />
    </div>)
}

function DangerousHtmlBlock(props: {value: string}) {
    return (<div dangerouslySetInnerHTML={{__html: props.value}} />)
}

function Question(props: {question: Definition, number: number}) {
    return (
        <div>
            <h1>Question #{props.number}, <small>{props.question.ItemId}</small></h1>
            <div style={{padding: "20px", margin: "20px", border: "1px solid black"}}>
                <DangerousHtmlBlock value={props.question.AssignmentHtml} />
            </div>
        </div>
    )
}

function Answers(props: {options: Option[]}) {
    return (
        <div>
            <h1>Options ({props.options.length})</h1>
            {props.options.map(option => {
                return (
                    <fieldset key={option.Key} style={{margin: "20px", color: option.IsCorrect ? "green" : "red"}}>
                        <legend>{option.Key}</legend>
                        <DangerousHtmlBlock value={option.OptionHtml} />
                    </fieldset>
                )
            })}
        </div>
    )
}

function Controls(props: {onNext?: () => void, onPrev?: () => void, itemId?: ItemId}) {
    let section: string
    switch (props.itemId?.section) {
        case 1: section = "Číselné obory"; break;
        case 2: section = "Algebraické výrazy"; break;
        case 3: section = "Rovnice a nerovnice"; break;
        case 4: section = "Funkce"; break;
        case 5: section = "Fin. matematika a posloupnosti"; break;
        case 6: section = "Planimetrie"; break
        case 7: section = "Stereometrie"; break;
        case 8: section = "Analytická geometrie"; break;
        case 9: section = "Kombinatorika, pravděpodobnost, statistika"; break;
        default: section = "Neznámá sekce";
    }

    const tags = `scio,${props.itemId?.id}`;
    const [isCopied, setIsCopied] = useState(false);

    const withStateReset = (callback?: () => void) => {
        return () => {
            setIsCopied(false);
            callback?.();
        }
    };

    const copyToClipboard = () => {
        const copy = async () => {
            await navigator.clipboard.writeText(tags)
            setIsCopied(true);
        };

        copy();
    }

    return(
        <Fragment>
            <h1>Meta</h1>

            <div style={{padding: "20px"}}>
                <p>Sekce: {section}</p>
                <p>Tags: {tags} <button disabled={isCopied} onClick={copyToClipboard}>{isCopied ? 'Copied!' : 'Copy'}</button></p>
            </div>

            <div style={{padding: "20px", margin: "20px", border: "1px solid black"}}>
                <button disabled={props.onPrev == undefined} onClick={withStateReset(props.onPrev)}>Prev</button>
                <button disabled={props.onNext == undefined} onClick={withStateReset(props.onNext)}>Next</button>
            </div>
        </Fragment>
    )
}

function Solution(props: {solution: string[]}) {
    return (
        <div>
            <h1>Solution</h1>
            <ol>
                {props.solution.map(solution => {
                    return (
                        <li>
                            <DangerousHtmlBlock value={solution} />
                        </li>
                    )
                })}
            </ol>

            {props.solution.map(solution => {
                return (
                    <textarea key={solution} style={{boxSizing: "border-box", padding: "15px", width: "100%"}} rows={10} disabled={true}>
                        {solution}
                    </textarea>
                );
            })}

        </div>
    )
}
