
import React, { useState } from 'react';
import Content from '../data/cs-combined.json';
import TeX from "@matejmazur/react-katex";
import { Copy } from './Copy';

type Task = {
    type: "inline-math" | "block-math" | "text";
    value: string;
}

type Question = {
    id: string;
    difficulty: number;
    task: string[];
    answers: { text: string; isCorrect: boolean; }[];
    topic?: string;
    section?: string;
    timeComplexitySeconds?: number;
}

export function MathmanExtractor(props: {}) {
    const [questionIndex, setQuestionIndex] = useState(0);
    const [testQuestionsOnly, setTestQuestionsOnly] = useState(false);
    const [complexityFilter, setComplexityFilter] = useState<number | undefined>();
    const questions: Question[] = Content.topics.reduce((partial, topic) => {
        const a: Question[] = topic.testQuestions.map(q => {
            const f = Content.questions.find(cand => cand.id === q.id)!;
            console.log(q.id);
            return {...f, topic: topic.title}}
        );
        const b: Question[] = topic.structuredQuestions.reduce((partial: Question[], section) => {
            const n: Question[] = section.questions.map(q => { return {...(Content.questions.find(cand => cand.id === q.id)!), topic: topic.title, section: section.title }; })
            return partial.concat(n);
        }, [] as Question[]);
        return partial.concat(a.concat(b));
    }, [] as Question[])
    .filter(q => (!testQuestionsOnly || q.timeComplexitySeconds !== undefined) && (complexityFilter === undefined || q.difficulty === complexityFilter) );
    const question = questions[questionIndex];
    const goPrev = () => { setQuestionIndex(questionIndex - 1) }
    const goNext = () => { setQuestionIndex(questionIndex + 1) }
    const updateFilter = (diff: string) => {
        if (diff === "-1") {
            setComplexityFilter(undefined);
        } else {
            setComplexityFilter(Number.parseInt(diff))
        }
    }
    const rawTask = question.task.map(t => {
        if (t.startsWith("_latex")) {
            const val = t.replace("_latex", "");
            return `\\[\n${val}\n\\]`;
        } else {
            return t;
        }
    }).join("\n");
    const tags = `mathman,${question.id}`
    const questionTitle = (
        <span>
            Question
            <Copy value={rawTask} />
        </span>
    );

    return (
        <div>
            <Section title="Controls">
                <p>
                    <button disabled={questionIndex == 0} onClick={goPrev}>Prev</button>
                    <button disabled={questionIndex == questions.length - 1} onClick={goNext}>Next</button>
                </p>
                <p>
                    <label htmlFor="test-q">
                        <input type="checkbox" id="test-q" value="test-only" onChange={() => setTestQuestionsOnly(!testQuestionsOnly) } />
                        Show test questions only
                    </label>
                </p>
                <p>
                    Filter by complexity:
                    <select onChange={(a) => updateFilter(a.target.value)}>
                        <option value="-1">All</option>
                        <option value="1">Easy (1)</option>
                        <option value="2">Medium (2)</option>
                        <option value="3">Hard (3)</option>
                    </select>
                </p>
            </Section>
            <Section style={{backgroundColor: question.timeComplexitySeconds !== undefined ? undefined : "orange"}} title="Meta">
                <p>
                    Question: {questionIndex + 1} of {questions.length}
                </p>
                <p>
                    Complexity: {question.difficulty} of 3 (hardest)
                </p>
                <p>
                    Tags: {tags}
                    <Copy value={tags} />
                </p>
                {question.timeComplexitySeconds &&
                    <p>
                        Time complexity: {question.timeComplexitySeconds}s ({question.timeComplexitySeconds / 60}m)
                    </p>
                }
                {question.topic &&
                    <p>
                        Topic: {question.topic} {question.section && `, ${question.section}`}
                    </p>
                }
                
            </Section>
            <Section title={questionTitle}>
                <div>
                    {question.task.map(task => {
                        if (task.startsWith("_latex")) {
                            return <TeX math={task.replace("_latex", "") }/>
                        } else {
                            return <p>{task}</p>
                        }
                    })}
                </div>
                
                <hr style={{margin: "45px 0"}} />
                
                <textarea disabled style={{width: "100%"}} rows={12} value={rawTask}/>
            </Section>
            <Section title="Answers">
                {question.answers.map((a, i) => {
                    const title = (
                        <span>
                            {i+1}
                            <Copy value={a.text}/>
                        </span>
                    );
                    return (
                        <Section style={{borderColor: a.isCorrect ? "green" : "red"}} title={title}>
                            <TeX math={a.text} />
                        </Section>
                    );
                })}
            </Section>
        </div>
    );
}

const Section: React.FC<{title: React.ReactNode, style?: React.CSSProperties}> = (props) => {
    return (
        <fieldset style={{margin: "20px", padding: "20px", ...props.style}}>
            <legend>{props.title}</legend>
            {props.children}
        </fieldset>
    );
}
