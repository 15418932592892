import { useState } from "react";

export const Copy: React.FC<{value: string}> = (props) => {
    const [isCopied, setIsCopied] = useState(false);
    const copy = () => {
        const setClipboard = async () => {
            await navigator.clipboard.writeText(props.value)
        }
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 3000)
        setClipboard()
    }

    return (
        <button onClick={copy} disabled={isCopied}>{isCopied ? "Copied!" : "Copy"}</button>
    );
}
