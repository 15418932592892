import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { MathmanExtractor } from './components/MathmanExtractor';
import 'katex/dist/katex.min.css';
import { ElsaSatExtractor } from './components/ElsaSatExtractor';
import { Extractor } from './components/Extractor';

type Extractor = {
  id: string;
  name: string;
  render: () => React.ReactNode;
}

const extractors: Extractor[] = [
  { id: "mathman", name: "MathMan", render: () => <MathmanExtractor /> },
  { id: "elsa-sat", name: "ELSA SAT", render: () => <ElsaSatExtractor /> },
  { id: "scio", name: "Scio", render: () => <Extractor /> },
];

function App() {
  const [extractor, setExtractor] = useState<Extractor | undefined>(undefined);

  const selectExtractor = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setExtractor(extractors.find(ex => ex.id === event.target.value));
  };

  return (
    <Page>
        <select onChange={selectExtractor}>
            <option>Select Questions Set</option>

            { extractors.map(extractor => (
              <option value={extractor.id}>{extractor.name}</option>
            )) }
        </select>

        <hr style={{border: "none", borderTop: "1px solid lightgray"}} />

        { extractor &&
          extractor.render()
        }
    </Page>
  );
}

const Page : React.FC<{}> = props => {
  return (
      <div className="page">        
          {props.children}
      </div>
  );
}

export default App;
